import React, { RefObject } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import ErrorTypography from '../../../shared/components/ErrorTypography'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'

interface IErrorPage {
  variant?: string
  error: any
  firstElement: RefObject<HTMLElement>
  lastElement: RefObject<HTMLElement>
}

const ErrorPage = ({
  error,
  firstElement,
  lastElement,
  variant,
}: IErrorPage) => {
  const { messageSnack, messageSnackErrorInfo, messageSnackErrorGuide } =
    useTypedSelector((state) => state.app.snack)

  const isSaleComplexError = variant === 'saleComplex'
  return (
    <>
      <Box ref={firstElement} sx={{ display: 'none' }} />
      <Stack
        alignItems="center"
        height="calc(100vh - 340px)"
        justifyContent="center"
      >
        {isSaleComplexError && <ErrorTypography error={messageSnack} />}
        {isSaleComplexError && (
          <ErrorTypography error={messageSnackErrorInfo} variant="sub" />
        )}
        {isSaleComplexError && (
          <ErrorTypography error={messageSnackErrorGuide} variant="sub" />
        )}
        {!isSaleComplexError && error.img && (
          <img src={error.img} alt="image-error" width={100} />
        )}
        {!isSaleComplexError && <ErrorTypography error={error.message} />}
      </Stack>

      <Box ref={lastElement} sx={{ display: 'none' }} />
    </>
  )
}

export default ErrorPage
