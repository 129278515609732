import {
  ApplicationAction,
  ApplicationActionTypes,
  IAppMode,
  ISideBarFilter,
  SnackTypes,
} from './applicationReduxTypes'
import { Dispatch } from 'redux'
import {
  getDefaultDepartment,
  getFilter,
  postDefaultDepartment,
  postFilterStructure,
} from '../../api/filter'
import { IOptionsProps } from '../../types/types'
import { getDefaultUser } from '../../api/user'
import {
  ProductAction,
  ProductActionTypes,
} from '../../../features/Product/model/product/productReduxTypes'

import boxImage from '@images/box.png'
import {
  BasketAction,
  BasketActionTypes,
} from '../../../features/Basket/model/basket/basketReduxTypes'
import { DEFAULT_DEPARTMENT_STORAGE_KEY } from '../../consts/filter'
import { getDepartments } from '../../api/department'

export const saveFilter = (filters: ISideBarFilter) => {
  return async (dispatch: Dispatch<ApplicationAction>) => {
    try {
      await postFilterStructure(filters)
      dispatch({
        type: ApplicationActionTypes.SAVE_FILTER,
        payload: filters,
      })
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Ошибка сохранения фильтров',
      //   })
      // )
    }
  }
}

export const fetchAllFilters = () => {
  return async (dispatch: Dispatch<ApplicationAction | BasketAction>) => {
    try {
      let { data } = await getFilter()
      if (data.content.filterStructure) {
        const settings = JSON.parse(data.content.filterStructure)
        dispatch({
          type: ApplicationActionTypes.SAVE_FILTER,
          payload: settings,
        })
      }
      if (data.content.filterValues) {
        const settings = JSON.parse(data.content.filterValues)
        dispatch({
          type: ApplicationActionTypes.SAVE_FILTER_VALUES,
          payload: settings,
        })
      }
      if (data.content.basketSnapshot) {
        const settings = JSON.parse(data.content.basketSnapshot)
        dispatch({
          type: BasketActionTypes.ADD_BASKET_RECORDS,
          payload: settings,
        })
      }
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Ошибка получения фильтров',
      //   })
      // )
    }
  }
}

export const saveDefaultDepartment = (payload: IOptionsProps | null) => {
  return async (dispatch: Dispatch<ProductAction | ApplicationAction>) => {
    try {
      await postDefaultDepartment(payload)
      dispatch({
        type: ProductActionTypes.FETCH_PRODUCTS_ERROR,
        payload: !payload
          ? { message: 'Выберите грузополучателя', img: boxImage }
          : {},
      })
      dispatch({
        type: ApplicationActionTypes.SAVE_DEFAULT_DEPARTMENT,
        payload,
      })
      if (!payload) return
      dispatch({ type: ProductActionTypes.RESET_FILTER_PRODUCTS })
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Ошибка сохранения грузополучателя',
      //   })
      // )
    }
  }
}

export const fetchCurrentDepartment = (departmentId: string) => {
  return async (dispatch: Dispatch<ProductAction | ApplicationAction>) => {
    try {
      const department = (await getDepartments(null, null, departmentId)).data
        .content.data?.[0] as IOptionsProps
      await postDefaultDepartment(department)
      dispatch({
        type: ProductActionTypes.FETCH_PRODUCTS_ERROR,
        payload: !department
          ? { message: 'Выберите грузополучателя', img: boxImage }
          : {},
      })
      dispatch({
        type: ApplicationActionTypes.SAVE_DEFAULT_DEPARTMENT,
        payload: department,
      })
    } catch (e) {
      // return e
    }
  }
}

export const fetchDefaultUser = () => {
  return async (dispatch: Dispatch<ApplicationAction>) => {
    try {
      let { data } = await getDefaultUser()
      if (!data.content) return
      const settings = data.content
      dispatch({
        type: ApplicationActionTypes.SAVE_DEFAULT_USER,
        payload: settings,
      })
    } catch (e) {
      dispatch(
        showSnack({
          typeSnack: SnackTypes.ERROR,
          messageSnack: 'Ошибка получения пользователя',
        })
      )
    }
  }
}

// export const fetchAllDepartments = () => {
//   return async (dispatch: Dispatch<ApplicationAction>) => {
//     try {
//       const deparments = await (await getDepartments()).data.content.data

//       // dispatch({
//       //   type: ApplicationActionTypes.GET_ALL_DEPARTMENTS,
//       //   payload:
//       // })
//     } catch (e) {}
//   }
// }
export const fetchDefaultDepartment = () => {
  return async (dispatch: Dispatch<ApplicationAction | ProductAction>) => {
    try {
      const {
        data: {
          content: { keyValueSettings },
        },
      } = await getDefaultDepartment()

      const customStorage = keyValueSettings.find(
        (item: any) => item.key === DEFAULT_DEPARTMENT_STORAGE_KEY
      )

      if (!customStorage) {
        throw new Error()
      }

      const customStorageParsed = JSON.parse(customStorage.value)

      if (!customStorageParsed) {
        dispatch({
          type: ProductActionTypes.FETCH_PRODUCTS_ERROR,
          payload: { message: 'Выберите грузополучателя', img: boxImage },
        })
        return
      }
      dispatch({
        type: ApplicationActionTypes.SAVE_DEFAULT_DEPARTMENT,
        payload: customStorageParsed,
      })
    } catch (e) {
      dispatch(
        showSnack({
          typeSnack: SnackTypes.ERROR,
          messageSnack: 'Ошибка получения грузополучателя',
        })
      )
    }
  }
}

export const saveScrollPosition = (position: number): ApplicationAction => ({
  type: ApplicationActionTypes.SAVE_SCROLL_POSITION,
  payload: position,
})

export const setLoadingEnable = (): ApplicationAction => ({
  type: ApplicationActionTypes.LOADING_ENABLE,
})

export const setLoadingDisable = (): ApplicationAction => ({
  type: ApplicationActionTypes.LOADING_DISABLE,
})

export const showSnack = (payload: {
  typeSnack: SnackTypes.SUCCESS | SnackTypes.WARNING | SnackTypes.ERROR
  messageSnack?: string
  messageSnackErrorInfo?: string
  messageSnackErrorGuide?: string
  messageSnackErrorCode?: string
  snackForDownload?: boolean
  handleSnackClick?: any
}): ApplicationAction => ({
  type: ApplicationActionTypes.SHOW_SNACK,
  payload,
})
export const sendErrorData = (payload: {
  messageSnack?: string
  messageSnackErrorInfo?: string
  messageSnackErrorGuide?: string
}): ApplicationAction => ({
  type: ApplicationActionTypes.SEND_ERROR_DATA,
  payload,
})

export const hideSnack = (): ApplicationAction => ({
  type: ApplicationActionTypes.HIDE_SNACK,
})

export const editItemsModeEnable = (payload: IAppMode): ApplicationAction => ({
  type: ApplicationActionTypes.EDIT_ITEMS_MODE_ENABLE,
  payload,
})
