import {
  ApplicationAction,
  ApplicationActionTypes,
  IApplicationState,
  ModeList,
  SnackTypes,
} from './applicationReduxTypes'
import { IOptionsProps } from '../../types/types'

const initialState: IApplicationState = {
  defaultDepartment: null as IOptionsProps | null,
  loading: false,
  scrollPosition: 0,
  snack: {
    openSnack: false,
    typeSnack: SnackTypes.SUCCESS,
    messageSnack: '',
    messageSnackErrorInfo: '',
    messageSnackErrorGuide: '',
    messageSnackErrorCode: '',
    snackForDownload: false,
    handleSnackClick: null,
  },
  appMode: {
    mode: ModeList.UNSET,
    data: '',
  },
  user: { fullName: '', email: '', id: '' },
  searchQuery: '',
  sideBarFilter: {
    producerCountryFilter: false,
    expirationDateFilter: false,
    gtinFilter: false,
    boxQuantityFilterMin: false,
    producerNameFilter: false,
    producerPriceFilterMin: false,
    minimalQuantityFilterMin: false,
    internationalNameFilter: false,
    vatRateFilter: false,
    remainExpirationPercentFilter: false,
    priceFilter: false,
    registerPriceFilterMin: false,
    nameFilter: false,
    supplierIdFilter: false,
    packQuantityFilterMin: false,
    esCodeFilter: false,
    wholesaleMarkupFilterMin: false,
    marketingEventTypesFilter: false,
  },
  filterValues: {},
}

export const applicationReducer = (
  state = initialState,
  action: ApplicationAction
): IApplicationState => {
  switch (action.type) {
    case ApplicationActionTypes.SEARCH_QUERY:
      return { ...state, searchQuery: action.payload }
    case ApplicationActionTypes.SAVE_DEFAULT_DEPARTMENT:
      return { ...state, defaultDepartment: action.payload }
    case ApplicationActionTypes.SAVE_DEFAULT_USER:
      return { ...state, user: action.payload }
    case ApplicationActionTypes.SAVE_FILTER:
      return { ...state, sideBarFilter: action.payload }
    case ApplicationActionTypes.SAVE_FILTER_VALUES:
      return { ...state, filterValues: action.payload }
    case ApplicationActionTypes.SAVE_SCROLL_POSITION:
      return { ...state, scrollPosition: action.payload }
    case ApplicationActionTypes.LOADING_ENABLE:
      return { ...state, loading: true }
    case ApplicationActionTypes.LOADING_DISABLE:
      return { ...state, loading: false }
    case ApplicationActionTypes.SHOW_SNACK:
      return {
        ...state,
        snack: {
          openSnack: true,
          ...action.payload,
        },
      }
    case ApplicationActionTypes.SEND_ERROR_DATA:
      return {
        ...state,
        snack: {
          openSnack: false,
          ...action.payload,
        },
      }
    case ApplicationActionTypes.HIDE_SNACK:
      return {
        ...state,
        snack: {
          ...state.snack,
          openSnack: false,
        },
      }
    case ApplicationActionTypes.EDIT_ITEMS_MODE_ENABLE:
      return {
        ...state,
        appMode: { ...action.payload },
      }

    default:
      return state
  }
}
