import React from 'react'
import Typography from '@mui/material/Typography'

const ErrorTypography = ({
  error,
  variant = 'main',
}: {
  error: string
  variant?: 'main' | 'sub'
}) => {
  const isSub = variant === 'sub'

  return (
    <Typography
      sx={{
        textTransform: isSub ? 'none' : 'uppercase',
        fontSize: isSub ? '14px' : '18px',
        fontWeight: isSub ? 400 : 600,
        maxWidth: isSub ? '80%' : '350px',
        color: 'text.secondary',
        marginTop: '10px',
        textAlign: 'center',
      }}
    >
      {error}
    </Typography>
  )
}

export default ErrorTypography
